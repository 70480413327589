/* General Body Style */
body {
  background-color: #1a1a1a;
  font-family: 'Press Start 2P', cursive;
  color: #ffffff;
  text-align: center;
}

/* Main Monitor Container */
.monitor-container {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 20px;
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  border: 5px solid #333;
  border-radius: 15px;
  background-color: #111;
  box-shadow: 0px 0px 20px rgba(0, 255, 0, 0.4);
}

/* Section Styles */
.monitor-section {
  padding: 20px;
  border-radius: 8px;
  background-color: #222;
  border: 2px solid #444;
}

/* Heartbeat Section */
.heartbeat-section {
  height: 100px;
  background-color: #000;
  border: 2px solid #00ff00;
}

/* Text Section */
.text-section {
  font-size: 24px;
  color: #ffffff;
}

/* Button Section */
.button-section {
  text-align: center;
}

/* Heartbeat Animation Container */
.heartbeat-container {
  width: 100%;
  height: 100px;
  overflow: hidden;
  position: relative;
  background-color: black;
  margin-bottom: 20px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="200" height="100"><polyline fill="none" stroke="%2300ff00" stroke-width="2" points="0,50 20,50 30,10 40,90 50,50 70,50 80,10 90,90 100,50 120,50 130,10 140,90 150,50 170,50 180,10 190,90 200,50"/></svg>');
  background-repeat: repeat-x;
  background-size: auto 100%;
  animation: heartbeat-move 2s linear infinite;
}

/* Heartbeat Animation */
@keyframes heartbeat-move {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -200px 0;
  }
}

/* Animated Text Styles */
.animated-text h1 {
  font-size: 24px;
  line-height: 1.5;
}

.typewriter-text {
  color: #00cc66;
}

.maryam-name {
  color: #f3a414;
}

/* Button Styling */
.button {
  background-color: #00cc66;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  transition: background-color 0.3s;
  font-family: 'Press Start 2P', cursive;
}

.button:hover {
  background-color: #00994d;
}

.button:disabled {
  background-color: grey;
  cursor: not-allowed;
}

/* Meme Image Styling */
.meme {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}
